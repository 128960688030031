import { createReducer, on, Action } from "@ngrx/store";
import * as valvulasActions from './valvulas.actions';
import { Valvula } from 'adgas_shared';
import { initialState, adapter, ValvulasState } from './valvulas.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        valvulasActions.LoadValvulas,
        valvulasActions.UpdateValvula,
        valvulasActions.CreateValvula,
        valvulasActions.DeleteValvula,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        valvulasActions.LoadValvulasSuccess,
        ( state,{valvulas} ) => {
            return adapter.setAll(
                valvulas,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        valvulasActions.UpdateValvulaFailed,
        valvulasActions.LoadValvulasFailed,
        valvulasActions.CreateValvulaFailed,
        valvulasActions.DeleteValvulaFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        valvulasActions.UpdateValvulaSuccess,
        (state, {valvula}) => {
            const valvulaUpdate: Update<Valvula> = {
                id: valvula._id,
                changes:{
                    ...valvula
                }
            };
            return adapter.updateOne(
                valvulaUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        valvulasActions.CreateValvulaSuccess,
        (state, {valvula}) => {
            return adapter.addOne(
                valvula,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        valvulasActions.DeleteValvulaSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function valvulasReducer(state: ValvulasState | undefined, action: Action){
    return reducer(state, action);
}