import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TanquesState, adapter } from './tanques.state';

const getTanquesState =
  createFeatureSelector<TanquesState>('tanques');


const { selectAll } = adapter.getSelectors();

export const getAllTanques =
  createSelector(
    getTanquesState,
    selectAll
  );
