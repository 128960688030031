import { createReducer, on, Action } from "@ngrx/store";
import * as usersActions from './users.actions';
import { User } from 'adgas_shared';
import { initialState, adapter, UsersState } from './users.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        usersActions.LoadUsers,
        usersActions.UpdateUser,
        usersActions.CreateUser,
        usersActions.DeleteUser,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        usersActions.LoadUsersSuccess,
        ( state,{users} ) => {
            return adapter.setAll(
                users,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        usersActions.UpdateUserFailed,
        usersActions.LoadUsersFailed,
        usersActions.CreateUserFailed,
        usersActions.DeleteUserFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        usersActions.UpdateUserSuccess,
        (state, {user}) => {
            const userUpdate: Update<User> = {
                id: user._id,
                changes:{
                    ...user
                }
            };
            return adapter.updateOne(
                userUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        usersActions.CreateUserSuccess,
        (state, {user}) => {
            return adapter.addOne(
                user,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        usersActions.DeleteUserSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function usersReducer(state: UsersState | undefined, action: Action){
    return reducer(state, action);
}