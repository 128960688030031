import { Descarga } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as descargasSelector from './descargas.selectors';
import * as descargasActions from './descargas.actions';
import { Observable } from 'rxjs';
import { DescargasState } from './descargas.state';

@Injectable()
export class DescargasFacade {

  descargas$: Observable<Descarga[]> = this.store.pipe(select(descargasSelector.selectAll));

  constructor(private store: Store<DescargasState>) {}

  load(pipaId): void {
    this.store.dispatch(descargasActions.LoadDescargas(pipaId));
  }

  update(descarga: Descarga): void {
    this.store.dispatch(descargasActions.UpdateDescarga({ descarga }));
  }
  create(descarga: Descarga): void {
    this.store.dispatch(descargasActions.CreateDescarga({ descarga }));
  }

  delete(_id: string): void {
    this.store.dispatch(descargasActions.DeleteDescarga({ _id }));
  }
  getDescargaById(_id: string): Observable<any> {
    return this.store.pipe(
        select(
            descargasSelector.getDescargasById({_id})
        )
    )
  }
  getDescargasbyType(type: string):Observable<any> {
    return this.store.pipe(
        select(
          descargasSelector.getDescargasByTypeSelector({type})
       )
    );
  }
}
