import { createAction, props } from "@ngrx/store";
import { Tanque } from 'adgas_shared';


/** Load Tanques **/
export const LoadTanques = createAction(
    '[Tanques/API] Load Tanques'
);

export const LoadTanquesSuccess = createAction(
    '[Tanques/API] Load Tanques Success',
    props<{tanques: Tanque[]}>()
);

export const LoadTanquesFailed = createAction(
    '[Tanques/API] Load Tanques Failed',
    props<{error:any}>()
)
/** Create Tanques **/
export const CreateTanque = createAction(
    '[Tanques/API] Create Tanque',
    props<{tanque: Tanque}>()
);

export const CreateTanqueSuccess = createAction(
    '[Tanques/API] Create Tanque Success',
    props<{tanque: Tanque}>()
);

export const CreateTanqueFailed = createAction(
    '[Tanques/API] Create Tanque Failed',
    props<{error:any}>()
)
/** Update Tanques **/
export const UpdateTanque = createAction(
    '[Tanques/API] Update Tanque',
    props<{tanque: Tanque}>()
);

export const UpdateTanqueSuccess = createAction(
    '[Tanques/API] Update Tanque Success',
    props<{tanque: Tanque}>()
);

export const UpdateTanqueFailed = createAction(
    '[Tanques/API] Update Tanque Failed',
    props<{error:any}>()
)
/** Delete Tanques **/
export const DeleteTanque = createAction(
    '[Tanques/API] Delete Tanque',
    props<{_id: string}>()
);

export const DeleteTanqueSuccess = createAction(
    '[Tanques/API] Delete Tanque Success',
    props<{_id: string}>()
);

export const DeleteTanqueFailed = createAction(
    '[Tanques/API] Delete Tanque Failed',
    props<{error:any}>()
)