import { createAction, props } from "@ngrx/store";
import { Almacen } from 'adgas_shared';


/** Load Almacenes **/
export const LoadAlmacenes = createAction(
    '[Almacenes/API] Load Almacenes'
);

export const LoadAlmacenesSuccess = createAction(
    '[Almacenes/API] Load Almacenes Success',
    props<{almacenes: Almacen[]}>()
);

export const LoadAlmacenesFailed = createAction(
    '[Almacenes/API] Load Almacenes Failed',
    props<{error:any}>()
)
/** Create Almacenes **/
export const CreateAlmacen = createAction(
    '[Almacenes/API] Create Almacen',
    props<{almacen: Almacen}>()
);

export const CreateAlmacenSuccess = createAction(
    '[Almacenes/API] Create Almacen Success',
    props<{almacen: Almacen}>()
);

export const CreateAlmacenFailed = createAction(
    '[Almacenes/API] Create Almacen Failed',
    props<{error:any}>()
)
/** Update Almacenes **/
export const UpdateAlmacen = createAction(
    '[Almacenes/API] Update Almacen',
    props<{almacen: Almacen}>()
);

export const UpdateAlmacenSuccess = createAction(
    '[Almacenes/API] Update Almacen Success',
    props<{almacen: Almacen}>()
);

export const UpdateAlmacenFailed = createAction(
    '[Almacenes/API] Update Almacen Failed',
    props<{error:any}>()
)
/** Delete Almacenes **/
export const DeleteAlmacen = createAction(
    '[Almacenes/API] Delete Almacen',
    props<{_id: string}>()
);

export const DeleteAlmacenSuccess = createAction(
    '[Almacenes/API] Delete Almacen Success',
    props<{_id: string}>()
);

export const DeleteAlmacenFailed = createAction(
    '[Almacenes/API] Delete Almacen Failed',
    props<{error:any}>()
)