import { createReducer, on, Action } from "@ngrx/store";
import * as almacenesActions from './almacenes.actions';
import { Almacen } from 'adgas_shared';
import { initialState, adapter, AlmacenesState } from './almacenes.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        almacenesActions.LoadAlmacenes,
        almacenesActions.UpdateAlmacen,
        almacenesActions.CreateAlmacen,
        almacenesActions.DeleteAlmacen,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        almacenesActions.LoadAlmacenesSuccess,
        ( state,{almacenes} ) => {
            return adapter.setAll(
                almacenes,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        almacenesActions.UpdateAlmacenFailed,
        almacenesActions.LoadAlmacenesFailed,
        almacenesActions.CreateAlmacenFailed,
        almacenesActions.DeleteAlmacenFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        almacenesActions.UpdateAlmacenSuccess,
        (state, {almacen}) => {
            const almacenUpdate: Update<Almacen> = {
                id: almacen._id,
                changes:{
                    ...almacen
                }
            };
            return adapter.updateOne(
                almacenUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        almacenesActions.CreateAlmacenSuccess,
        (state, {almacen}) => {
            return adapter.addOne(
                almacen,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        almacenesActions.DeleteAlmacenSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function almacenesReducer(state: AlmacenesState | undefined, action: Action){
    return reducer(state, action);
}