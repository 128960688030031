import { FuseProgressBarService } from './../../../../@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './../../../services/auth.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-activation-user',
  templateUrl: './activation-user.component.html',
  styleUrls: ['./activation-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ActivationUserComponent implements OnInit {
    hide = true;
    activation_form: FormGroup;
    confirmationKey;
    confirm_password_error = '';
    ok = false;
    resetSuccess = false;
    path = '';
  constructor(
      private _fuseConfigService: FuseConfigService,
      private _fb: FormBuilder,
      private _route: ActivatedRoute,
      private _router: Router,
      private _authService: AuthService,
      private _snackBar: MatSnackBar,
      private _progressBar: FuseProgressBarService,
  ) { 
      // Configure the layout
      this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };
  }

  ngOnInit(): void {
      this.confirmationKey = this._route.snapshot.paramMap.get('confirmaitonKey');
      this.path = this._router.url.split('/')[1];
      this.activation_form = this._fb.group({
          password: ['', Validators.required],
          confirmPassword: ['', Validators.required],
          confirmationKey:[this.confirmationKey]
      });
      this.activation_form.get('confirmPassword').valueChanges.subscribe( value => {
          const password = this.activation_form.get('password').value;
          const confirmPassword = value;
          if(!password) return this.confirm_password_error = 'Las contraseñas no coinciden';
          if( password != confirmPassword) return this.confirm_password_error = 'Las contraseñas no coinciden';
          else return this.confirm_password_error = '';
      })
  }
  submit(){
      this._progressBar.show()
      if(this.path == 'change-password'){
        this._authService.resetPassword(this.activation_form.value).subscribe(active => {
            this._progressBar.hide();
            if(active.data.error){
                return this._snackBar.open(active.data.error, 'ok',{
                  duration: 10 * 1000,
              });
            }
            return this.resetSuccess = true;
        });

      }else{
          this._authService.activateUser(this.activation_form.value).subscribe(active => {
              this._progressBar.hide();
              if(active.data.error){
                  return this._snackBar.open(active.data.error, 'ok',{
                    duration: 10 * 1000,
                });
              }
              return this.ok = true;
          });
      }
  }

}
