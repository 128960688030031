import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

    {
        id       : 'applications',
        title    : 'Aplicaciones',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
        /* {
            id       : 'mapa',
            title    : 'Mapa',
            type     : 'item',
            icon     : 'location_on',
            url      : '/mapa',
        }, */
        {
            id: 'plantas',
            title: 'Plantas',
            type: 'item',
            icon: 'business',
            url: '/plantas'
        },
        {
            id: 'users',
            title: 'Usuarios',
            type: 'item',
            icon: 'person_outline',
            url: '/users'
        },
        {
            id       : 'pipas',
            title    : 'Pipas',
            type     : 'item',
            icon     : 'directions_bus',
            url: '/pipas'
        },
        {
            id       : 'almacenes',
            title    : 'Almacenes',
            type     : 'collapsable',
            icon     : 'location_city',
            children: [
                {
                    id:'administracion',
                    title: 'Administración',
                    type: 'item',
                    url: '/almacenes/administracion',
                    exactMatch: true
                },
                {
                    id:'suministro',
                    title: 'Suministro',
                    type: 'item',
                    url: '/almacenes/suministro',
                    exactMatch:true
                }
            ]
        },
        {
            id       : 'facturacion',
            title    : 'Facturación',
            type     : 'item',
            icon     : 'credit_card',
            url: '/facturacion'
        },
    ]
    },
    /* {
        id       : 'applications',
        title    : 'Aplicaciones',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id: 'catalogos',
                title: 'Catálogos',
                type: 'collapsable',
                icon: 'folder_special',
                children:[
                    {
                        id: 'plantas',
                        title: 'Plantas',
                        type: 'item',
                        icon: 'business',
                        url: '/plantas'
                    },
                    {
                        id: 'users',
                        title: 'Usuarios',
                        type: 'item',
                        icon: 'person_outline',
                        url: '/users'
                    },
                    {
                        id       : 'pipas',
                        title    : 'Pipas',
                        type     : 'item',
                        icon     : 'directions_bus',
                        url: '/pipas'
                    },
                    {
                        id       : 'notas',
                        title    : 'Notas',
                        type     : 'item',
                        icon     : 'receipt',
                        url: '/notas'
                    }
                ]
            }, */
/*             
            {
                id: 'tanques',
                title: 'Tanques',
                type: 'collapsable',
                icon: 'battery_full',
                children : [
                {
                    id        : 'almacen',
                    title     : 'Almacen',
                    type      : 'item',
                    url       : '/tanques/',
                    exactMatch: true
                },
                {
                    id        : 'tanque',
                    title     : 'Tanque',
                    type      : 'item',
                    url       : '/tanques/',
                    exactMatch: true
                },
                {
                    id        : 'carga',
                    title     : 'Cargas',
                    type      : 'item',
                    url       : '/tanques/',
                    exactMatch: true
                },
                ]
            },
            {
                id       : 'pipas',
                title    : 'Pipas',
                type     : 'collapsable',
                icon     : 'directions_bus',
                children : [
                    {
                        id        : 'inventario',
                        title     : 'Inventario',
                        type      : 'item',
                        url       : '/pipas/inventario',
                        exactMatch: true
                    },
                    {
                        id        : 'cargas',
                        title     : 'Cargas',
                        type      : 'item',
                        url       : '/pipas/carga',
                        exactMatch: true
                    },
                ]
            }, */
            /* {
                id       : 'pipas',
                title    : 'Pipas',
                type     : 'item',
                icon     : 'directions_bus',
                url      : '/pipas',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                } 
            }, */
        /* ] */
    
];
