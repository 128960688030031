import { Routes } from '@angular/router';

import {
  ActivationUserComponent,
} from './main/pages/activation-user/activation-user.component';
import { AuthGuard } from './services/guards/auth-guard.service';

export const appRoutes: Routes = [
  {
      path: 'login',
      loadChildren: () => import('./main/pages/authentication/login-2/login-2.module').then(m => m.Login2Module)
  },
  {
      path: 'not-found',
      loadChildren: () => import('./main/pages/404/error-404.module').then(m=>m.Error404Module)
  },
  {
      path: 'active-user/:confirmaitonKey',
      component: ActivationUserComponent
  },
  {
    path: 'change-password/:confirmaitonKey',
    component: ActivationUserComponent
  },
  {
    path: 'plantas',
    canActivateChild: [AuthGuard],
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren: () => import('./main/plantas/plantas.module').then(m => m.PlantasModule),
  },
  {
    path: 'users',
    canActivateChild: [AuthGuard],
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'operadores',
    canActivateChild: [AuthGuard],
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren: () => import('./main/operadores/operadores.module').then(m => m.OperadoresModule),
  },
  {
    path: 'pipas',
    canActivateChild: [AuthGuard],
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren: () => import('./main/pipas/pipas.module').then(m => m.PipasModule),
  },
  {
    path: 'almacenes',
    canActivateChild: [AuthGuard],
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren: () => import('./main/almacenes/almacenes.module').then(m => m.AlmacenesModule),
  },
  {  
    path: 'facturacion',
    canActivateChild: [AuthGuard],
    data: {
        role: ['administrador', 'cobranza']
    },
    loadChildren: () => import('./main/facturas/facturas.module').then(m => m.FacturasModule),
    },
  {
    path: 'tanques',
    canActivateChild: [AuthGuard],   
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren:() => import('./main/tanques/tanques.module').then(m => m.TanquesModule)
  },
  {
    path: 'mapa',
    canActivateChild: [AuthGuard],   
    data: {
        role: ['administrador', 'operador']
    },
    loadChildren:() => import('./main/map/map.module').then(m => m.MapModule)
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];
