import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OperadoresState, adapter } from './operadores.state';

const getOperadoresState =
  createFeatureSelector<OperadoresState>('operadores');


const { selectAll } = adapter.getSelectors();

export const getAllOperadores =
  createSelector(
    getOperadoresState,
    selectAll
  );
