import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as valvulasAction from './valvulas.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Valvula } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ValvulasEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(valvulasAction.LoadValvulas),
        mergeMap( () => 
            this._apiService.getValvulas().pipe(
                map( (valvulas:Valvula[]) => valvulasAction.LoadValvulasSuccess({valvulas})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(valvulasAction.LoadValvulasFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(valvulasAction.CreateValvula),
        mergeMap( ({valvula}) => 
            this._apiService.createValvula(valvula).pipe(
                map( (valvula:Valvula) => valvulasAction.CreateValvulaSuccess({valvula})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(valvulasAction.CreateValvulaFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(valvulasAction.UpdateValvula),
      mergeMap( ({valvula}) => 
        this._apiService.updateValvula(valvula).pipe(
            map( valvula => valvulasAction.UpdateValvulaSuccess({valvula}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(valvulasAction.UpdateValvulaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(valvulasAction.DeleteValvula),
      mergeMap( ({_id}) => 
        this._apiService.deleteValvula(_id).pipe(
            map( _id => valvulasAction.DeleteValvulaSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(valvulasAction.DeleteValvulaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}