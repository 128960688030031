import { Almacen } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as almacenesSelector from './almacenes.selectors';
import * as almacenesActions from './almacenes.actions';
import { Observable } from 'rxjs';
import { AlmacenesState } from './almacenes.state';

@Injectable()
export class AlmacenesFacade {

  almacenes$: Observable<Almacen[]> = this.store.pipe(select(almacenesSelector.selectAll));

  constructor(private store: Store<AlmacenesState>) {}

  load(): void {
    this.store.dispatch(almacenesActions.LoadAlmacenes());
  }

  update(almacen: Almacen): void {
    this.store.dispatch(almacenesActions.UpdateAlmacen({ almacen }));
  }
  create(almacen: Almacen): void {
    this.store.dispatch(almacenesActions.CreateAlmacen({ almacen }));
  }

  delete(_id: string): void {
    this.store.dispatch(almacenesActions.DeleteAlmacen({ _id }));
  }
  getAllByType(type: string):Observable<any> {
      return this.store.pipe(
          select(
            almacenesSelector.selectByType({type})
         )
      );
  }

}
