import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiResponse } from '../interfaces/api.interfaces';
import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})
export class ApiService {
    constructor(
        private _http: HttpClient,
        private _authService: AuthService
    ) { 
        this.createFakeDataPlantas();
    }
    public rols = [];

    public uploadDigitalSpaces(file){
        return new Promise(async(resolve, reject) => {
            const spacesEndpoint = new AWS.Endpoint('sfo3.digitaloceanspaces.com');
            const s3 = new AWS.S3({
                endpoint: spacesEndpoint,
                ...environment.do
            });

            var params = {
            Body: file.image,
            Bucket: "adgas",
            Key: file.filename,
            ContentType:"image/jpg",
            ACL: 'public-read'
            };

            s3.putObject(params, function(err, data) {
                if (err) reject(err);
                else{
                    resolve(data);
                }
            });
    
           /*  fetch(file.image).then( async response=> {
                let blob = await response.blob();
                var params = {
                Body: blob,
                Bucket: "adgas",
                Key: file.filename,
                ContentType:"image/jpg",
                ACL: 'public-read'
                };
    
                s3.putObject(params, function(err, data) {
                    if (err) reject(err);
                    else{
                        console.log('Escribimos los datos ok ');
                        console.log(data);
                        resolve(data);
                    }
                });
            }) */
        })
    }
    public createFakeDataPlantas(){
        this._http.get(`${environment.api_url}/rols`).pipe(
            map((response: ApiResponse) => response.data.rols)
        ).subscribe( rols => {
            this.rols = rols;
        })
    }
    // Plantas
    public getPlantas(){
        return this._http.get(`${environment.api_url}/plantas`).pipe( 
            map( (response: ApiResponse) => response.data.plantas)  
        );
    }
    public createPlanta(planta){
        return this._http.post(`${environment.api_url}/plantas`,planta).pipe(
            map( (response: ApiResponse) => response.data.planta)
        )
    }
    public deletePlanta(id:string){
        return this._http.delete(`${environment.api_url}/planta/${id}`).pipe(
            map( (response: ApiResponse) => response.data.planta._id)
        )
    }
    public updatePlanta(planta){
        const {_id, ...updatedPlanta} = planta;
        return this._http.patch(`${environment.api_url}/planta/${_id}`,updatedPlanta).pipe(
            map( (response: ApiResponse) => {
                return response.data.planta
            })
        );
    }

      // Descargas
    public getDescargas({pipaId}){
        return this._http.get(`${environment.api_url}/getFolios?pipa=${pipaId}`).pipe(
        map( (response: ApiResponse) => {
            console.log('La respuesta');
            console.log(response.data.folios);
            return response.data.folios;
        })
        );
    }
    public getDescargaById(descargaId){
        return this._http.get(`${environment.api_url}/descarga/${descargaId}`).pipe(
            map( (response: ApiResponse) => {
                return response.data.descarga;
            })
            );
    }
    public createDescarga(descarga){
        return this._http.post(`${environment.api_url}/descargas`,descarga).pipe(
            map( (response: ApiResponse) => response.data.descargas)
        )
    }
    public deleteDescarga(id:string){
        return this._http.delete(`${environment.api_url}/descarga/${id}`).pipe(
            map( (response: ApiResponse) => response.data.descarga._id)
        )
    }
    public updateDescarga(descarga){
        const {_id, ...updatedDescarga} = descarga;
        return this._http.patch(`${environment.api_url}/descarga/${_id}`,updatedDescarga).pipe(
            map( (response: ApiResponse) => {
                return response.data.descarga
            })
        );
    }

    // Almacenes
    public getAlmacenes(){
        return this._http.get(`${environment.api_url}/almacenes`).pipe( 
            map( (response: ApiResponse) => response.data.almacenes),
            map(almacenes =>{
                return almacenes.map(almacen => ({...almacen, qr:`https://fotos.devadg.com/qr/${almacen._id}_text.png`}))
            })
        );
    }
    public createAlmacen(almacen){
        return this._http.post(`${environment.api_url}/almacenes`,almacen).pipe(
            map( (response: ApiResponse) => response.data.almacen)
        )
    }
    public deleteAlmacen(id:string){
        return this._http.delete(`${environment.api_url}/almacen/${id}`).pipe(
            map( (response: ApiResponse) => response.data.almacen._id)
        )
    }
    public updateAlmacen(almacen){
        const {_id, ...updatedAlmacen} = almacen;
        return this._http.patch(`${environment.api_url}/almacen/${_id}`,updatedAlmacen).pipe(
            map( (response: ApiResponse) => {
                return response.data.almacen
            })
        );
    }
    public getCargasAlmacen(){
        
    }

    // Pipas
    public getPipas(){
        return this._http.get(`${environment.api_url}/pipas`).pipe( 
            map( (response: ApiResponse) => response.data.pipas)  
        );
    }
    public createPipa(pipa){
        return this._http.post(`${environment.api_url}/pipas`,pipa).pipe(
            map( (response: ApiResponse) => response.data.pipa)
        )
    }
    public deletePipa(id:string){
        return this._http.delete(`${environment.api_url}/pipa/${id}`).pipe(
            map( (response: ApiResponse) => response.data.pipa._id)
        )
    }
    public updatePipa(pipa){
        
        const {_id, ...updatedPipa} = pipa;
        return this._http.patch(`${environment.api_url}/pipa/${_id}`,updatedPipa).pipe(
            map( (response: ApiResponse) => {
                return response.data.pipa
            }),
            catchError( (e:any) => {
                console.log('Error al setear pipa');
                console.log(e)
                return of(e);
            })
        );
    }

    // Cargas
    public getCargas(pipaId){
        return this._http.get(`${environment.api_url}/cargas?pipa=${pipaId}`).pipe( 
            map( (response: ApiResponse) => {
                const cargas = response.data.cargas;
                return cargas.map( (carga:any) => {
                    //get if all descargas are already validated
                    let validated = true;
                    carga.descargas.map(descarga => {
                        if(!descarga.validated) validated = false;
                    })
                    return {
                        ...carga,
                        validated,
                        fotoTicket: environment.fotos_url+carga.fotoTicket
                    }
                })
            }),
        );
    }
    public createCarga(carga){
        return this._http.post(`${environment.api_url}/cargas`,carga).pipe(
            map( (response: ApiResponse) => response.data.cargas)
        )
    }
    public deleteCarga(id:string){
        return this._http.delete(`${environment.api_url}/carga/${id}`).pipe(
            map( (response: ApiResponse) => response.data.carga._id)
        )
    }
    public updateCarga(carga){
        const {_id, ...updatedCarga} = carga;
        return this._http.patch(`${environment.api_url}/carga/${_id}`,updatedCarga).pipe(
            map( (response: ApiResponse) => {
                return response.data.carga
            })
        );
    }
    

    // Descargas
    public getCargaDetail(cargaId){
        return this._http.get(`${environment.api_url}/carga/${cargaId}`).pipe( 
            map( (response: ApiResponse) =>{
                return {
                    ...response.data.carga,
                    fotoTicket: environment.fotos_url+response.data.carga.fotoTicket,
                    fotoTotalizadorInicial: environment.fotos_url+response.data.carga.fotoTotalizadorInicial,
                }
            }) 
        );
    }

    public validateDescarga(changes, currentDescarga){
        try{
           let {fotoTicket = null, fotoInicial = null, fotoFinal = null, ...payload} = changes; 
           const promises = [];
            if(changes.fotoTicket){ 
                const obj = {image: changes.fotoTicket.file, filename:`${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/nota.jpg`};
                promises.push(this.uploadDigitalSpaces(obj));
                payload.fotoTicket = `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/nota.jpg`;
            }
            if(changes.fotoInicial){
                const obj = {image: changes.fotoInicial.file, filename:`${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/inicial.jpg`};
                promises.push(this.uploadDigitalSpaces(obj));
                payload.fotoInicial = `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/inicial.jpg`;
            }
            if(changes.fotoFinal){
                const obj = {image: changes.fotoFinal.file, filename: `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/final.jpg`};
                promises.push(this.uploadDigitalSpaces(obj));
                payload.fotoFinal = `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/final.jpg`;
            }
            if(promises.length > 0 ){
                return of(Promise.all(promises)).pipe( 
                    switchMap( (newPhoto) => {
                        //Removing photos from changes because we keep the photo url 
                        return this._http.put(`${environment.api_url}/validateDescarga`,payload).pipe( 
                            map( (response: ApiResponse) => response.data) 
                        );
                    })
                )
            }else{
                return this._http.put(`${environment.api_url}/validateDescarga`,changes).pipe( 
                    map( (response: ApiResponse) => response.data) 
                );
            }
        }catch(e){
            console.log('Fallo en upload digital ocean');
            console.error(e);
            return of({message:e, descarga:{folio:1, tipo:'suministro'}});
        }
    }

    public validateCarga(payload){
        console.log('Validaremoos la siguiente carga');
        console.log(payload);
        return this._http.put(`${environment.api_url}/validateCarga`,payload).pipe( 
            map( (response: ApiResponse) => response.data) 
        );
    }

    public getFolio(pipaId, filter){
        return this._http.get(`${environment.api_url}/folios?pipaId=${pipaId}&tipo=${filter}`).pipe(
            map( (response: ApiResponse) => {
                return response.data;
            })
        );
    }
    public createFolios(payload){
        return this._http.post(`${environment.api_url}/createFolios`,payload).pipe(
            map( (response: ApiResponse) => {
                return response.data;
            })
        );
    }

    //Operadores
    public getOperadores(){
        return this._http.get(`${environment.api_url}/operadores`).pipe( 
            map( (response: ApiResponse) => response.data.operadores)  
        );
    }
    public createOperador(operador){
        return this._http.post(`${environment.api_url}/operadores`,operador).pipe(
            map( (response: ApiResponse) => response.data.operador)
        )
    }
    public deleteOperador(id:string){
        return this._http.delete(`${environment.api_url}/operador/${id}`).pipe(
            map( (response: ApiResponse) => response.data.operador._id)
        )
    }
    public updateOperador(operador){
        const {_id, ...updatedOperador} = operador;
        return this._http.patch(`${environment.api_url}/operador/${_id}`,updatedOperador).pipe(
            map( (response: ApiResponse) => {
                return response.data.operador
            })
        );
    }

    //Users
    public getUsers(){
        return this._http.get(`${environment.api_url}/users`).pipe( 
            map( (response: ApiResponse) => response.data.users)  
        );
    }
    public createUser(user){
        return this._http.post(`${environment.api_url}/register`,user).pipe(
            map( (response: ApiResponse) => response.data.user)
        )
    }
    public deleteUser(id:string){
        return this._http.delete(`${environment.api_url}/user/${id}`).pipe(
            map( (response: ApiResponse) => response.data.user._id)
        )
    }
    public updateUser(user){
        const {_id, ...updatedUser} = user;
        return this._http.patch(`${environment.api_url}/user/${_id}`,updatedUser).pipe(
            map( (response: ApiResponse) => {
                return response.data.user
            })
        );
    }

    //Valvulas
    public getValvulas(){
        return this._http.get(`${environment.api_url}/valvulas`).pipe( 
            map( (response: ApiResponse) => response.data.valvulas)  
        );
    }
    public createValvula(valvula){
        return this._http.post(`${environment.api_url}/valvulas`,valvula).pipe(
            map( (response: ApiResponse) => response.data.valvula)
        )
    }
    public deleteValvula(id:string){
        return this._http.delete(`${environment.api_url}/valvula/${id}`).pipe(
            map( (response: ApiResponse) => response.data.valvula._id)
        )
    }
    public updateValvula(valvula){
        const {_id, ...updatedPlanta} = valvula;
        return this._http.patch(`${environment.api_url}/valvula/${_id}`,updatedPlanta).pipe(
            map( (response: ApiResponse) => {
                return response.data.valvula
            })
        );
    }

    //Tanques
    public getTanques(){
        return this._http.get(`${environment.api_url}/tanques`).pipe( 
            map( (response: ApiResponse) => response.data.tanques)  
        );
    }
    public createTanque(tanque){
        return this._http.post(`${environment.api_url}/tanques`,tanque).pipe(
            map( (response: ApiResponse) => response.data.tanque)
        )
    }
    public deleteTanque(id:string){
        return this._http.delete(`${environment.api_url}/tanque/${id}`).pipe(
            map( (response: ApiResponse) => response.data.tanque._id)
        )
    }
    public updateTanque(tanque){
        const {_id, ...updatedPlanta} = tanque;
        return this._http.patch(`${environment.api_url}/tanque/${_id}`,updatedPlanta).pipe(
            map( (response: ApiResponse) => {
                return response.data.tanque
            })
        );
    } 

    //Precios
    public createPrecios(precios){
        return this._http.post(`${environment.api_url}/precios`,precios).pipe(
            map( (response: ApiResponse) => response.data.precios)
        )
    }
    public updatePrecios(precios, id){
        return this._http.patch(`${environment.api_url}/precio/${id}`,precios).pipe(
            map( (response: ApiResponse) => response.data.precio)
        )
    }

    public getTodayPrecios(){
        return this._http.get(`${environment.api_url}/precios`).pipe( 
            map( (response: ApiResponse) => response.data.precios)  
        );
    }

    // Pipas Location
    public getPipasLocation(date = new Date(), pipa_id: string){
        return this._http.get(`${environment.api_url}/locations/${pipa_id}?date=${date}`).pipe(
            map( (response: ApiResponse) => response.data.locations)
        )
    }

    // Folios
    public getFolios({pipa, tipo}){
        return this._http.get(`${environment.api_url}/getFolios?pipa=${pipa}&tipo=${tipo}`).pipe(
            map( (response: ApiResponse) => response.data.folios)
        )
    }
    // Facturas
    public getFacturas(){
        return this._http.get(`${environment.api_url}/getFacturas`).pipe(
            map( (response: ApiResponse) => response.data.folios),
            map( folios => {
               return  folios.map(folio => ({
                    ...folio,
                    fotoTicket: environment.fotos_url+folio.fotoTicket
                }))
            })
        )
    }


}