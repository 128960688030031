import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { differenceInSeconds } from 'date-fns';
import decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import {
  map,
  tap,
} from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';
import { ApiResponse } from '../interfaces/api.interfaces';

@Injectable({providedIn: 'root'})
export class AuthService {
    helper = new JwtHelperService();
    user;
    loginSubject = new BehaviorSubject(false);
    constructor(
        private http: HttpClient,
        public router: Router,
    ) { }
    
    login(object:any){
        return this.http.post(`${environment.api_url}/login`,{...object},{withCredentials:true}).pipe(
            tap( (response: ApiResponse) => {
                console.log('Respuesta del login');
                console.log(response)
                if(response.data.user){
                    this.user = response.data.user;
                    this.loginSubject.next(true);
                    localStorage.setItem('token', response.data.user.token);
                }
            })
        );
    }

    activateUser(creds:any){
        return this.http.post(`${environment.api_url}/activate`, {...creds},{withCredentials: true}).pipe(
            tap( (response: ApiResponse) => {
            })
        )
    }
    resetPassword(creds:any){
        return this.http.post(`${environment.api_url}/resetPassword`, {...creds},{withCredentials: true}).pipe(
            tap( (response: ApiResponse) => {
            })
        )
    }
    logout(){
        console.log('Deslogeando');
        return this.http.get(`${environment.api_url}/logout`).subscribe( (data:ApiResponse) => {
            if(data.meta.success){
                localStorage.removeItem('token');
                this.router.navigate(['/login']);
            }
        });
    }

    refresh(){
        return this.http.post(`${environment.api_url}/refresh`,null,{withCredentials:true}).pipe(
            map( (resp: ApiResponse) => {
                console.log('La respuesta del refresh');
                console.log(resp);
                if(resp.meta.success){
                    return resp.data.token;
                }
                return resp;         
            }),
            tap( token => {
                localStorage.setItem('token', token)
            })
        );
    }

    isAuthenticated(): boolean {
        /* console.log('Expiration date');
        console.log(this.getTokenExpirationDate());
        console.log('En', this.getTimeToRefresh()); */
        const token = localStorage.getItem('token');
        if(!token) return false;
        
        return true;
        //return !this.helper.isTokenExpired(token);
    }

    getToken(){
        return localStorage.getItem('token');
    }

    getTokenExpirationDate(){
        return this.helper.getTokenExpirationDate(this.getToken());
    }

    getTimeToRefresh(){
        const today = new Date();
        return differenceInSeconds(this.getTokenExpirationDate(),today);
    }
    
    decodeUser(){
        try{
            this.user = decode(localStorage.getItem('token'));
            return this.user;
        }catch{
            return {nombre: '', apellidos:''}
        }
    }
    getUser(){
        return this.decodeUser();
    }
}