import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
/* import { UsersFormDialogComponent } from 'app/main/almacenForm/almacen-form.component'; */
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Almacen } from 'adgas_shared';
import {
  fromEvent,
  Observable,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import {
  FuseConfirmDialogComponent,
} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {
  FuseProgressBarService,
} from '@fuse/components/progress-bar/progress-bar.service';

import { AlmacenesFacade } from '../../store/almacenes/almacenes.facade';

@Component({
    selector     : 'almacenes',
    templateUrl  : './almacenes.component.html',
    styleUrls    : ['./almacenes.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({
                height: '0px',
                minHeight: '0',
                visibility: 'hidden'
            })),
            state('expanded', style({
                height: '*',
                visibility: 'visible'
            })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})
export class AlmacenesComponent implements OnInit, OnDestroy
{

    @ViewChild('dialogContent', {static: false})
    dialogContent: TemplateRef<any>;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    
    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    almacenType = null;

    @ViewChild('expandRow') expandedRow: ElementRef; 
    contacts: any;
    almacen: Almacen = {
        direccion: '',
        serie: '',
        nombre: '',
        capacidad: null,
        created: null,
        comisionista: '',
        administrador: '',
        tanques: [],
        last: [],
        num_tanques: null,
        fabricacion: null,
        fecha_alta: null,
        reinstalacion: null,
        billing_type: null,
        modelo: '',
        balance: null,
        descuento: null,
        marca: '',
        type: '',
        costo_administracion: null,
        factor: null,
        dia_lectura: '',
        zona: '',
        dia_carga: '',
        optimo: null,
        bonificacion: null,
        contacto: '',
        inv_inicial: null,
        supervisor: '',
    };
    
    dataSource;
    action = 'edit';
    almacenes$:Observable<Almacen[]> = this._almacenesFacade.almacenes$;
    almacenes: Almacen[] = [];
    displayedColumns = ['nombre', 'capacidad', 'medicion', 'diamedicion','tipo','serie' ,'expand'];
    selectedContacts: any[];
    checkboxes: {};
    isExpansionDetailRow = (i: number, row: any) =>  {
        return row == this.expandedElement;
    }
    expandedElement: any;
    almacenForm: FormGroup;
    currentAlmacen: any;
    dialogRef: any;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * 
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _almacenesFacade: AlmacenesFacade,
        public _matDialog: MatDialog,
        private _storage: AngularFireStorage,
        private _afs: AngularFirestore,
        private _progressBar: FuseProgressBarService,
        private _fb: FormBuilder,
        private _activeRouter: ActivatedRoute,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void
    {  
       /*  this.almacenes$.subscribe( (almacenes:any) => {
            this.almacenes = almacenes;
            
            
        }); */
        this.almacenType =this._activeRouter.snapshot.url[0].path;
        this._almacenesFacade.getAllByType(this.almacenType).subscribe(elements => {
            this.almacenes = elements;
            this.initDataSource(elements);
        });

        fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(150),
            distinctUntilChanged()
        )
        .subscribe(() => {
            if ( !this.dataSource )
            {
                return;
            }
            this.dataSource.filter = this.filter.nativeElement.value;
        });
     }
     nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
          for (const k in data[key]) {
            if (data[key][k] !== null) {
              search = this.nestedFilterCheck(search, data[key], k);
            }
          }
        } else {
          search += data[key];
        }
        return search;
      }
     initAlmacenForm(){
        this.almacenForm = this._fb.group({
            _id: [this.almacen._id],
            nombre:[this.almacen.nombre],
            capacidad:[this.almacen.capacidad],
            serie:[this.almacen.serie],
            dia_lectura:[this.almacen.dia_lectura],
            dia_carga:[this.almacen.dia_carga],
            direccion:this._fb.group({
                calle:[this.almacen.direccion.calle || ''],
                colonia:[this.almacen.direccion.colonia || ''],
                delegacion:[this.almacen.direccion.delegacion || ''],
                cp:[this.almacen.direccion.cp || ''],
                estado:[this.almacen.direccion.estado || ''],
                ciudad:[this.almacen.direccion.ciudad || '']
            }),
            clave:[this.almacen.clave],
            created:[this.almacen.created],
            comisionista:[this.almacen.comisionista],
            administrador:[this.almacen.administrador],
            tanques:[this.almacen.tanques],
            last:[this.almacen.last],
            num_tanques:[this.almacen.num_tanques],
            fabricacion:[this.almacen.fabricacion],
            fecha_alta:[this.almacen.fecha_alta],
            reinstalacion:[this.almacen.reinstalacion],
            billing_type:[this.almacen.billing_type],
            modelo:[this.almacen.modelo],
            balance:[this.almacen.balance],
            descuento:[this.almacen.descuento],
            marca:[this.almacen.marca],
            type:[this.almacen.type],
            costo_administracion:[this.almacen.costo_administracion],
            factor:[this.almacen.factor],
            zona:[this.almacen.zona],
            optimo:[this.almacen.optimo],
            bonificacion:[this.almacen.bonificacion],
            contacto:[this.almacen.contacto],
            inv_inicial:[this.almacen.inv_inicial],
            supervisor:[this.almacen.supervisor],
        });
    }

    expandElement(row,flag, action='edit'){
        console.log(row);
        this.almacen = row;
        this.action = action;
        this.initAlmacenForm();
        if(flag){
            this.expandedElement = row;
            this.currentAlmacen = row;
        }else{
            this.expandedElement = null;
            this.currentAlmacen = null;
        }
    }
    getPropertyByPath(obj: Object, pathString: string) {
        return pathString.split('.').reduce((o, i) => o[i], obj);
    }

    initDataSource( elements:Almacen[] ){
        this.dataSource = new MatTableDataSource(elements);
        this.initAlmacenForm();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property: string) => {
            switch (property) {
                case 'last.medicion':
                    if(item.last.length){
                        return item.last[0].final;
                    }else{
                        return 0;
                    }
                case 'last.fecha':
                    if(item.last.length){
                        return item.last[0].fecha;
                    }else{
                        return 0;
                    }
                case 'tipo':
                    return item['type'];
                default:
                return item[property];
            }
        };
        this.dataSource.sort = this.sort;
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    editContact(almacen: Almacen): void
    {
        /* this.dialogRef = this._matDialog.open(UserFormComponent, {
            panelClass: 'almacen-form',
            data      : {
                almacen: almacen,
                action : 'edit'
            }
        }); */

        this.dialogRef.afterClosed()
            .subscribe( async(response) => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    /**
                     * Save
                     */
                    case 'save':
                        this.saveUser('update', response[1], almacen);
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this._almacenesFacade.delete(almacen._id);

                        break;
                }
            });
    }

    /**
     * Delete User
     */
    deleteUser(almacen): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = '¿Estás seguro que lo quieres eliminar?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._almacenesFacade.delete(almacen);
            }
            this.confirmDialogRef = null;
        });

    }


    addAlmacen(){
        let newAlmacen = {
            direccion: '',
            serie: '',
            nombre: '',
            capacidad: null,
            created: null,
            comisionista: '',
            administrador: '',
            tanques: [],
            last: [],
            num_tanques: null,
            fabricacion: null,
            fecha_alta: null,
            reinstalacion: null,
            billing_type: null,
            modelo: '',
            balance: null,
            descuento: null,
            marca: '',
            type: this.almacenType,
            costo_administracion: null,
            factor: null,
            dia_lectura: '',
            zona: '',
            dia_carga: '',
            optimo: null,
            bonificacion: null,
            contacto: '',
            inv_inicial: null,
            supervisor: '',
        }
        this.almacenes.unshift(newAlmacen);
        this.initDataSource(this.almacenes);
        this.expandElement(newAlmacen, true, 'new');
    }

    saveAlmacen(){
        console.log('Salvando el almacen');
        console.log(this.almacenForm.value);
        this._almacenesFacade.update(this.almacenForm.value);
    }
    saveUser(action, response, almacen = null){
        let{ avatar,...almacenToUpdate} = response.getRawValue();
        if(action == 'update'){
            almacenToUpdate._id = almacen._id;
        }
        if(avatar && avatar.file){
            this._progressBar.show();
            const id = this._afs.createId()
            const task = this._storage.upload(id, avatar.file);
            task.then(() => {
                const ref = this._storage.ref(id);
                ref.getDownloadURL().subscribe(async url => {
                    try {
                    almacenToUpdate.avatar = url;
                    this._progressBar.hide();
                    this._almacenesFacade[action](almacenToUpdate);
                    }catch{
                    }
                });
            });
        }else{
            this._almacenesFacade[action](almacenToUpdate);
        }
    }
}