import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  _isRefreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService,public router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    request = this._addToken(request,this.authService.getToken());

    return next.handle(request).pipe(
        catchError( (err: HttpErrorResponse) => {
            if(err.status === 401){
                if(err.error.data.name === 'TokenExpiredError'){
                    // Token Refresh
                    return this._handle401Response(request, next);
                }
            }
            return throwError( err );
        })
    );
  }
  private _handle401Response(request: HttpRequest<any>, next: HttpHandler){
    if (!this._isRefreshing) {
        this._isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.authService.refresh().pipe( 
                switchMap( (token:string) => {
                this._isRefreshing = false;
                if(!token){
                    localStorage.removeItem('token');
                    this.router.navigate(['/login']);
                }
                this.refreshTokenSubject.next(token);
                return next.handle(this._addToken(request, token));
            }));
    

    } else {
        return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(jwt => {
            return next.handle(this._addToken(request, jwt));
            }));
    }
  }
  private _addToken(request, token){
    return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
  }
}