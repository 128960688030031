import { Pipa } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as pipasSelector from './pipas.selectors';
import * as pipasActions from './pipas.actions';
import { Observable } from 'rxjs';
import { PipasState } from './pipas.state';

@Injectable()
export class PipasFacade {

  pipas$: Observable<Pipa[]> = this.store.pipe(select(pipasSelector.selectAll));
  constructor(private store: Store<PipasState>) {}

  getPipaById(pipaId):Observable<any>{
    return this.store.pipe(select(pipasSelector.getPipaById,{pipaId}));
  }

  load(): void {
    this.store.dispatch(pipasActions.LoadPipas());
  }

  update(pipa: Pipa): void {
    this.store.dispatch(pipasActions.UpdatePipa({ pipa }));
  }
  create(pipa: Pipa): void {
    this.store.dispatch(pipasActions.CreatePipa({ pipa }));
  }

  delete(_id: string): void {
    this.store.dispatch(pipasActions.DeletePipa({ _id }));
  }

}
