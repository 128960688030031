import { createReducer, on, Action } from "@ngrx/store";
import * as pipasActions from './pipas.actions';
import { Pipa } from 'adgas_shared';
import { initialState, adapter, PipasState } from './pipas.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        pipasActions.LoadPipas,
        pipasActions.UpdatePipa,
        pipasActions.CreatePipa,
        pipasActions.DeletePipa,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        pipasActions.LoadPipasSuccess,
        ( state,{pipas} ) => {
            return adapter.setAll(
                pipas,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        pipasActions.UpdatePipaFailed,
        pipasActions.LoadPipasFailed,
        pipasActions.CreatePipaFailed,
        pipasActions.DeletePipaFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        pipasActions.UpdatePipaSuccess,
        (state, {pipa}) => {
            const pipaUpdate: Update<Pipa> = {
                id: pipa._id,
                changes:{
                    ...pipa
                }
            };
            return adapter.updateOne(
                pipaUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        pipasActions.CreatePipaSuccess,
        (state, {pipa}) => {
            return adapter.addOne(
                pipa,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        pipasActions.DeletePipaSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function pipasReducer(state: PipasState | undefined, action: Action){
    return reducer(state, action);
}