import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlmacenesState, adapter } from './almacenes.state';

const getAlmacenesState =
  createFeatureSelector<AlmacenesState>('almacenes');


export const { selectAll, selectEntities } = adapter.getSelectors(getAlmacenesState );

export const selectByType = (props: {type: string}) => {
    return createSelector(selectAll,(entities) => {
        return entities.filter(almacen => almacen.type == props.type)
    });
}
