import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as tanquesAction from './tanques.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Tanque } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class TanquesEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(tanquesAction.LoadTanques),
        mergeMap( () => 
            this._apiService.getTanques().pipe(
                map( (tanques:Tanque[]) => tanquesAction.LoadTanquesSuccess({tanques})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(tanquesAction.LoadTanquesFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(tanquesAction.CreateTanque),
        mergeMap( ({tanque}) => 
            this._apiService.createTanque(tanque).pipe(
                map( (tanque:Tanque) => tanquesAction.CreateTanqueSuccess({tanque})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(tanquesAction.CreateTanqueFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(tanquesAction.UpdateTanque),
      mergeMap( ({tanque}) => 
        this._apiService.updateTanque(tanque).pipe(
            map( tanque => tanquesAction.UpdateTanqueSuccess({tanque}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(tanquesAction.UpdateTanqueFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(tanquesAction.DeleteTanque),
      mergeMap( ({_id}) => 
        this._apiService.deleteTanque(_id).pipe(
            map( _id => tanquesAction.DeleteTanqueSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(tanquesAction.DeleteTanqueFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}