import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePickerComponent } from './image-picker/image-picker.component';


@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,

    ],
    exports: [ImagePickerComponent],
    declarations: [ImagePickerComponent],
    providers: [],
})
export class ComponentsModuleModule { }
