import { Tanque } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as tanquesSelector from './tanques.selectors';
import * as tanquesActions from './tanques.actions';
import { Observable } from 'rxjs';
import { TanquesState } from './tanques.state';

@Injectable()
export class TanquesFacade {

  tanques$: Observable<Tanque[]> = this.store.pipe(select(tanquesSelector.getAllTanques));

  constructor(private store: Store<TanquesState>) {}

  load(): void {
    this.store.dispatch(tanquesActions.LoadTanques());
  }

  update(tanque: Tanque): void {
    this.store.dispatch(tanquesActions.UpdateTanque({ tanque }));
  }
  create(tanque: Tanque): void {
    this.store.dispatch(tanquesActions.CreateTanque({ tanque }));
  }

  delete(_id: string): void {
    this.store.dispatch(tanquesActions.DeleteTanque({ _id }));
  }

}
