import { createAction, props } from "@ngrx/store";
import { Pipa } from 'adgas_shared';


/** Load Pipas **/
export const LoadPipas = createAction(
    '[Pipas/API] Load Pipas'
);

export const LoadPipasSuccess = createAction(
    '[Pipas/API] Load Pipas Success',
    props<{pipas: Pipa[]}>()
);

export const LoadPipasFailed = createAction(
    '[Pipas/API] Load Pipas Failed',
    props<{error:any}>()
)
/** Create Pipas **/
export const CreatePipa = createAction(
    '[Pipas/API] Create Pipa',
    props<{pipa: Pipa}>()
);

export const CreatePipaSuccess = createAction(
    '[Pipas/API] Create Pipa Success',
    props<{pipa: Pipa}>()
);

export const CreatePipaFailed = createAction(
    '[Pipas/API] Create Pipa Failed',
    props<{error:any}>()
)
/** Update Pipas **/
export const UpdatePipa = createAction(
    '[Pipas/API] Update Pipa',
    props<{pipa: Pipa}>()
);

export const UpdatePipaSuccess = createAction(
    '[Pipas/API] Update Pipa Success',
    props<{pipa: Pipa}>()
);

export const UpdatePipaFailed = createAction(
    '[Pipas/API] Update Pipa Failed',
    props<{error:any}>()
)
/** Delete Pipas **/
export const DeletePipa = createAction(
    '[Pipas/API] Delete Pipa',
    props<{_id: string}>()
);

export const DeletePipaSuccess = createAction(
    '[Pipas/API] Delete Pipa Success',
    props<{_id: string}>()
);

export const DeletePipaFailed = createAction(
    '[Pipas/API] Delete Pipa Failed',
    props<{error:any}>()
)