import { DescargasFacade } from './descargas/descargas.facade';
import { DescargasEffects } from './descargas/descargas.effects';
import { AlmacenesEffects } from './almacenes/almacenes.effects';
import { AlmacenesFacade } from './almacenes/almacenes.facade';

import { ValvulasEffects } from './valvulas/valvulas.effects';
import { TanquesEffects } from './tanques/tanques.effects';
import { OperadoresFacade } from './operadores/operadores.facade';
import { PlantasEffects } from './plantas/plantas.effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { plantasReducer } from './plantas/plantas.reducer';
import { PlantasFacade } from './plantas/plantas.facade';
import { OperadoresEffects } from './operadores/operadores.effects';
import { operadoresReducer } from './operadores/operadores.reducer';
import { tanquesReducer } from './tanques/tanques.reducer';
import { TanquesFacade } from './tanques/tanques.facade';
import { valvulasReducer } from './valvulas/valvulas.reducer';
import { ValvulasFacade } from './valvulas/valvulas.facade';
import { UsersEffects } from './users/users.effects';
import { usersReducer } from './users/users.reducer';
import { UsersFacade } from './users/users.facade';
import { pipasReducer } from './pipas/pipas.reducer';
import { PipasEffects } from './pipas/pipas.effects';
import { PipasFacade } from './pipas/pipas.facade';
import { almacenesReducer } from './almacenes/almacenes.reducer';
import { descargasReducer } from './descargas/descargas.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot([], {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    StoreModule.forFeature('plantas', plantasReducer),
    StoreModule.forFeature('operadores', operadoresReducer),
    StoreModule.forFeature('tanques', tanquesReducer),
    StoreModule.forFeature('valvulas', valvulasReducer),
    StoreModule.forFeature('users', usersReducer),
    StoreModule.forFeature('pipas', pipasReducer),
    StoreModule.forFeature('almacenes', almacenesReducer),
    StoreModule.forFeature('descargas', descargasReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
        PlantasEffects,
        OperadoresEffects,
        TanquesEffects,
        ValvulasEffects,
        UsersEffects,
        PipasEffects,
        AlmacenesEffects,
        DescargasEffects
    ])
  ],
  providers:[
      PlantasFacade,
      OperadoresFacade,
      TanquesFacade,
      ValvulasFacade,
      UsersFacade,
      PipasFacade,
      AlmacenesFacade,
      DescargasFacade
  ]
})
export class RootStoreModule { }
