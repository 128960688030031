import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AlmacenesModule } from '../almacenes/almacenes.module';
import { MapboxComponent } from './mapbox/mapbox.component';

const routes = [
    {
        path     : '',
        component: MapboxComponent
    }
];

@NgModule({
  declarations: [MapboxComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AlmacenesModule
  ]
})
export class MapModule { }
