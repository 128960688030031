import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as almacenesAction from './almacenes.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Almacen } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AlmacenesEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(almacenesAction.LoadAlmacenes),
        mergeMap( () => 
            this._apiService.getAlmacenes().pipe(
                map( (almacenes:Almacen[]) => almacenesAction.LoadAlmacenesSuccess({almacenes})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(almacenesAction.LoadAlmacenesFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(almacenesAction.CreateAlmacen),
        mergeMap( ({almacen}) => 
            this._apiService.createAlmacen(almacen).pipe(
                map( (almacen:Almacen) => almacenesAction.CreateAlmacenSuccess({almacen})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(almacenesAction.CreateAlmacenFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(almacenesAction.UpdateAlmacen),
      mergeMap( ({almacen}) => 
        this._apiService.updateAlmacen(almacen).pipe(
            map( almacen => almacenesAction.UpdateAlmacenSuccess({almacen}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(almacenesAction.UpdateAlmacenFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(almacenesAction.DeleteAlmacen),
      mergeMap( ({_id}) => 
        this._apiService.deleteAlmacen(_id).pipe(
            map( _id => almacenesAction.DeleteAlmacenSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(almacenesAction.DeleteAlmacenFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}