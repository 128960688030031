import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as descargasAction from './descargas.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Descarga } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DescargasEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(descargasAction.LoadDescargas),
        mergeMap( ({pipaId}) => 
            this._apiService.getDescargas({pipaId}).pipe(
                map( (descargas:Descarga[]) => descargasAction.LoadDescargasSuccess({descargas})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(descargasAction.LoadDescargasFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(descargasAction.CreateDescarga),
        mergeMap( ({descarga}) => 
            this._apiService.createDescarga(descarga).pipe(
                map( (descarga:Descarga) => descargasAction.CreateDescargaSuccess({descarga})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(descargasAction.CreateDescargaFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(descargasAction.UpdateDescarga),
      mergeMap( ({descarga}) => 
        this._apiService.updateDescarga(descarga).pipe(
            map( descarga => descargasAction.UpdateDescargaSuccess({descarga}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(descargasAction.UpdateDescargaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(descargasAction.DeleteDescarga),
      mergeMap( ({_id}) => 
        this._apiService.deleteDescarga(_id).pipe(
            map( _id => descargasAction.DeleteDescargaSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(descargasAction.DeleteDescargaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}