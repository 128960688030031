import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  public socket;
  constructor() {
    /* this.socket = io(environment.SOCKET_ENDPOINT,{transports: ['polling']}); */
   }
}
