import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Operador } from 'adgas_shared';

export interface OperadoresState extends EntityState<Operador> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<Operador> =
  createEntityAdapter<Operador>({
    // custom _id as selectedId
    selectId: operadorRef => operadorRef._id
  });

export const initialState: OperadoresState =
  adapter.getInitialState({
    loading: false,
    loaded: false
  });
