import { createReducer, on, Action } from "@ngrx/store";
import * as descargasActions from './descargas.actions';
import { Descarga } from 'adgas_shared';
import { initialState, adapter, DescargasState } from './descargas.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        descargasActions.LoadDescargas,
        descargasActions.UpdateDescarga,
        descargasActions.CreateDescarga,
        descargasActions.DeleteDescarga,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        descargasActions.LoadDescargasSuccess,
        ( state,{descargas} ) => {
            return adapter.setAll(
                descargas,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        descargasActions.UpdateDescargaFailed,
        descargasActions.LoadDescargasFailed,
        descargasActions.CreateDescargaFailed,
        descargasActions.DeleteDescargaFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        descargasActions.UpdateDescargaSuccess,
        (state, {descarga}) => {
            const descargaUpdate: Update<Descarga> = {
                id: descarga._id,
                changes:{
                    ...descarga
                }
            };
            return adapter.updateOne(
                descargaUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        descargasActions.CreateDescargaSuccess,
        (state, {descarga}) => {
            return adapter.addOne(
                descarga,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        descargasActions.DeleteDescargaSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function descargasReducer(state: DescargasState | undefined, action: Action){
    return reducer(state, action);
}