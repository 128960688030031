import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from 'adgas_shared';
import { UsersState, adapter } from './users.state';
const getUsersState =
  createFeatureSelector<UsersState>('users');

export const { selectAll } = adapter.getSelectors(getUsersState);

/**
 * Selector para filtro de usuarios por rol
 */
export const getUsersByRol = (rols: string[]) => {
    return createSelector(
        selectAll,
        (users: User[]) => {
            const filtered_users = users.filter( user => rols.includes(user.rol.name) && user.active );
            return filtered_users;
        }
    )
};