import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as pipasAction from './pipas.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Pipa } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PipasEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(pipasAction.LoadPipas),
        mergeMap( () => 
            this._apiService.getPipas().pipe(
                map( (pipas:Pipa[]) => pipasAction.LoadPipasSuccess({pipas})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(pipasAction.LoadPipasFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(pipasAction.CreatePipa),
        mergeMap( ({pipa}) => 
            this._apiService.createPipa(pipa).pipe(
                map( (pipa:Pipa) => pipasAction.CreatePipaSuccess({pipa})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(pipasAction.CreatePipaFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(pipasAction.UpdatePipa),
      mergeMap( ({pipa}) => 
        this._apiService.updatePipa(pipa).pipe(
            map( pipa => pipasAction.UpdatePipaSuccess({pipa}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(pipasAction.UpdatePipaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(pipasAction.DeletePipa),
      mergeMap( ({_id}) => 
        this._apiService.deletePipa(_id).pipe(
            map( _id => pipasAction.DeletePipaSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(pipasAction.DeletePipaFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}