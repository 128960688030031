import { Operador } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as operadoresSelector from './operadores.selector';
import * as operadoresActions from './operadores.actions';
import { Observable } from 'rxjs';
import { OperadoresState } from './operadores.state';

@Injectable()
export class OperadoresFacade {

  operadores$: Observable<Operador[]> = this.store.pipe(select(operadoresSelector.getAllOperadores));

  constructor(private store: Store<OperadoresState>) {}

  load(): void {
    this.store.dispatch(operadoresActions.LoadOperadores());
  }

  update(operador: Operador): void {
    this.store.dispatch(operadoresActions.UpdateOperador({ operador }));
  }
  create(operador: Operador): void {
    this.store.dispatch(operadoresActions.CreateOperador({ operador }));
  }

  delete(_id: string): void {
    this.store.dispatch(operadoresActions.DeleteOperador({ _id }));
  }

}
