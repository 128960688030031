import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Valvula } from 'adgas_shared';

export interface ValvulasState extends EntityState<Valvula> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<Valvula> =
  createEntityAdapter<Valvula>({
    // custom _id as selectedId
    selectId: valvulaRef => valvulaRef._id
  });

export const initialState: ValvulasState =
  adapter.getInitialState({
    loading: false,
    loaded: false
  });
