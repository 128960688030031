import { createReducer, on, Action } from "@ngrx/store";
import * as plantasActions from './plantas.actions';
import { Planta } from 'adgas_shared';
import { initialState, adapter, PlantasState } from './plantas.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        plantasActions.LoadPlantas,
        plantasActions.UpdatePlanta,
        plantasActions.CreatePlanta,
        plantasActions.DeletePlanta,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        plantasActions.LoadPlantasSucces,
        ( state,{plantas} ) => {
            return adapter.setAll(
                plantas,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        plantasActions.UpdatePlantaFailed,
        plantasActions.LoadPlantasFailed,
        plantasActions.CreatePlantaFailed,
        plantasActions.DeletePlantaFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        plantasActions.UpdatePlantaSucces,
        (state, {planta}) => {
            const plantaUpdate: Update<Planta> = {
                id: planta._id,
                changes:{
                    ...planta
                }
            };
            return adapter.updateOne(
                plantaUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        plantasActions.CreatePlantaSucces,
        (state, {planta}) => {
            return adapter.addOne(
                planta,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        plantasActions.DeletePlantaSucces,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function plantasReducer(state: PlantasState | undefined, action: Action){
    return reducer(state, action);
}