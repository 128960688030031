import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Tanque } from 'adgas_shared';

export interface TanquesState extends EntityState<Tanque> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<Tanque> =
  createEntityAdapter<Tanque>({
    // custom _id as selectedId
    selectId: tanqueRef => tanqueRef._id
  });

export const initialState: TanquesState =
  adapter.getInitialState({
    loading: false,
    loaded: false
  });
