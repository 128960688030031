import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlantasState, adapter } from './plantas.state';

const getPlantasState =
  createFeatureSelector<PlantasState>('plantas');


const { selectAll } = adapter.getSelectors();

export const getAllPlantas =
  createSelector(
    getPlantasState,
    selectAll
  );
