import { createReducer, on, Action } from "@ngrx/store";
import * as operadoresActions from './operadores.actions';
import { Operador } from 'adgas_shared';
import { initialState, adapter, OperadoresState } from './operadores.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        operadoresActions.LoadOperadores,
        operadoresActions.UpdateOperador,
        operadoresActions.CreateOperador,
        operadoresActions.DeleteOperador,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        operadoresActions.LoadOperadoresSucces,
        ( state,{operadores} ) => {
            return adapter.setAll(
                operadores,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        operadoresActions.UpdateOperadorFailed,
        operadoresActions.LoadOperadoresFailed,
        operadoresActions.CreateOperadorFailed,
        operadoresActions.DeleteOperadorFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        operadoresActions.UpdateOperadorSucces,
        (state, {operador}) => {
            const operadorUpdate: Update<Operador> = {
                id: operador._id,
                changes:{
                    ...operador
                }
            };
            return adapter.updateOne(
                operadorUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        operadoresActions.CreateOperadorSucces,
        (state, {operador}) => {
            return adapter.addOne(
                operador,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        operadoresActions.DeleteOperadorSucces,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function operadoresReducer(state: OperadoresState | undefined, action: Action){
    return reducer(state, action);
}