import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValvulasState, adapter } from './valvulas.state';

const getValvulasState =
  createFeatureSelector<ValvulasState>('valvulas');


const { selectAll } = adapter.getSelectors();

export const getAllValvulas =
  createSelector(
    getValvulasState,
    selectAll
  );
