import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as usersAction from './users.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UsersEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(usersAction.LoadUsers),
        mergeMap( () => 
            this._apiService.getUsers().pipe(
                map( (users:User[]) => usersAction.LoadUsersSuccess({users})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(usersAction.LoadUsersFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(usersAction.CreateUser),
        mergeMap( ({user}) => 
            this._apiService.createUser(user).pipe(
                map( (user:User) => usersAction.CreateUserSuccess({user})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(usersAction.CreateUserFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(usersAction.UpdateUser),
      mergeMap( ({user}) => 
        this._apiService.updateUser(user).pipe(
            map( user => usersAction.UpdateUserSuccess({user}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(usersAction.UpdateUserFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(usersAction.DeleteUser),
      mergeMap( ({_id}) => 
        this._apiService.deleteUser(_id).pipe(
            map( _id => usersAction.DeleteUserSuccess({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(usersAction.DeleteUserFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}