import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Descarga } from 'adgas_shared';

export interface DescargasState extends EntityState<Descarga> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<Descarga> =
  createEntityAdapter<Descarga>({
    // custom _id as selectedId
    selectId: descargaRef => descargaRef._id
  });

export const initialState: DescargasState =
  adapter.getInitialState({
    loading: false,
    loaded: false
  });
