import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as operadoresAction from './operadores.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Operador } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class OperadoresEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(operadoresAction.LoadOperadores),
        mergeMap( () => 
            this._apiService.getOperadores().pipe(
                map( (operadores:Operador[]) => operadoresAction.LoadOperadoresSucces({operadores})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(operadoresAction.LoadOperadoresFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(operadoresAction.CreateOperador),
        mergeMap( ({operador}) => 
            this._apiService.createOperador(operador).pipe(
                map( (operador:Operador) => operadoresAction.CreateOperadorSucces({operador})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(operadoresAction.LoadOperadoresFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(operadoresAction.UpdateOperador),
      mergeMap( ({operador}) => 
        this._apiService.updateOperador(operador).pipe(
            map( operador => operadoresAction.UpdateOperadorSucces({operador}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(operadoresAction.LoadOperadoresFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(operadoresAction.DeleteOperador),
      mergeMap( ({_id}) => 
        this._apiService.deleteOperador(_id).pipe(
            map( _id => operadoresAction.DeleteOperadorSucces({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(operadoresAction.LoadOperadoresFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}