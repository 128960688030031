import { FuseNavigation } from '@fuse/types';

export const navigationCobranza: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Aplicaciones',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
        {
            id: 'cobranza',
            title: 'Cobranza',
            type: 'item',
            icon: 'business',
            url: '/plantas'
        }]
    }
    
];
