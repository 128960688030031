import { Ubicacion } from 'adgas_shared';
import {
    environment
} from 'environments/environment';
import {
    Component,
    OnInit
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {
    GeoJson,
    FeatureCollection
} from './../../../services/map.class';
import { SocketioService } from 'app/services/socket.service';
import { ApiService } from 'app/services/api.service';
@Component({
    selector: 'app-mapbox',
    templateUrl: './mapbox.component.html',
    styleUrls: ['./mapbox.component.scss']
})
export class MapboxComponent implements OnInit {
    map: mapboxgl.Map;
    adg = [-99.1478289, 19.3956784];
    lineCords = [];
    constructor(private _socketService: SocketioService, private _apiService: ApiService) {}
    source: any;
    ngOnInit(): void {
        this.initMap();
       /*  let yesterday = new Date().setDate(new Date().getDate() - 1);
        this._apiService.getPipasLocation(new Date(yesterday), '5f9bb5a9578c15c0d4f62e9a').subscribe(locations => {
            const geojsons = [];
            locations.forEach(location => {
                const geo = new GeoJson([location.lng, location.lat]);
                geo.properties = {
                    pipa: location.pipa.nombre,
                    operdor: location.user.nombre + ' ' + location.user.apellidos,
                    speed: location.speed,
                    timestamp: new Date(location.date).getTime()
                }
                this.lineCords.push([location.lng, location.lat]);
                geojsons.push(geo);
            });
        }); */
    }
    initScoket(){
        this._socketService.socket.on('changePosition', async(location: Ubicacion) => {
            try{
                console.log(location);
                const pipa = await this.getPipaInfo(location.pipa); 
                const newSource = new FeatureCollection([new GeoJson([location.lng, location.lat],{title: 'Pipa1', operdor: 'otro'})]);
                console.log(newSource);
                this.source.setData(newSource);
                this.map.panTo([location.lng, location.lat]);
            }
            catch(e){
                console.warn(e);
            }
        });
    }

    getPipaInfo(pipaId){

    }

    initMap(){
        mapboxgl.accessToken = environment.mapbox.accessToken;
        this.map = new mapboxgl.Map({
            container: 'mapa',
            style: 'mapbox://styles/allberto/ckigcsal23c2r19qigswkf70u',
            center: this.adg, // LNG, LAT
            zoom: 16
        });
        this.map.on('load', () => {
            const el = document.createElement('div');
            el.className = 'adg-marker';
            el.style.backgroundImage = 'url("./../../../../assets/images/logos/logo-iso-green.svg")';
            el.style.width = '60px';
            el.style.height = '60px';
            el.style.backgroundRepeat = 'no-repeat';
            el.style.backgroundSize = 'contain';
            el.style.backgroundPosition = 'center center';
            new mapboxgl.Marker(el)
            .setLngLat(this.adg)
            .addTo(this.map);

            this.map.loadImage('https://firebasestorage.googleapis.com/v0/b/adgas-6b5b3.appspot.com/o/pipa.png?alt=media&token=3b2a0c5c-81af-49d1-a293-d7edc8cfad3c', (error, image) => {
                if(error){
                    console.error(error);
                    return;
                }
                this.map.addImage('pipa', image);
                /// register source
                this.map.addSource('one', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                });
                this.source =  this.map.getSource('one');
                
                this.map.addLayer({
                    'id': 'pipa',
                    'type': 'symbol',
                    'source': 'one',
                    'layout': {
                        'text-size': 24,
                        'text-transform': 'uppercase',
                        'icon-image': 'pipa',
                        "icon-size":0.1,
                        'text-offset': [0, 1.5],
                        "text-field": ['get', 'title'],
                        'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                        ],
                        'text-anchor': 'top'
                    },
                    'paint': {
                        'text-color': '#f16624',
                        'text-halo-color': '#fff',
                        'text-halo-width': 2
                    }
                }); 
                this.map.on('click', 'pipa', (e) => {
                    new mapboxgl.Popup()
                        .setLngLat(e.lngLat)
                        .setHTML("POPUP!")
                        .addTo(this.map);
                });
                this.initScoket();
            });
            

           /*  this.map.addSource('route', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': this.lineCords
                    }
                }
             });
            this.map.addLayer({
                    'id': 'route',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#3BFFA7',
                    'line-width': 8
                }
            }); */
            /* this.map.loadImage(
                'https://scontent.fmex23-1.fna.fbcdn.net/v/t1.0-9/128920497_717531105549071_8801260420799780769_o.jpg?_nc_cat=110&ccb=2&_nc_sid=09cbfe&_nc_ohc=1J27JTQzqoAAX95DgT2&_nc_ht=scontent.fmex23-1.fna&oh=ba2e29fd05ba462f62ceca3a583df62c&oe=5FF5568D',
                (error, image) => {
                    if (error) throw error;
                    this.map.addImage('cat', image);
                    this.map.addSource('point', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [{
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': this.adg
                                }
                            }]
                        }
                    });
                    this.map.addLayer({
                        'id': 'points',
                        'type': 'symbol',
                        'source': 'point',
                        'layout': {
                            'icon-image': 'cat',
                            'icon-size': 0.1
                        }
                    });
                }
            ); */
        });

        this.map.addControl(new mapboxgl.NavigationControl()); 
        document.getElementsByClassName('mapboxgl-ctrl-bottom-right')[0].remove();
        //// Add Marker on Click
        this.map.on('click', (event) => {
            console.log('Click');
            const coordinates = [event.lngLat.lng, event.lngLat.lat];
            console.log(coordinates);
            const newMarker = new GeoJson(coordinates, {
                message: 'Listo'
            })
            /* this.mapService.createMarker(newMarker) */
        });
    }

}