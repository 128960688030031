import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  FormDialogComponent,
} from 'app/components/form-dialog/form-dialog.component';
import { ApiService } from 'app/services/api.service';
import { AuthService } from 'app/services/auth.service';

import { SocketioService } from '../../../services/socket.service';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit
{
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    dialogRef: any;
    form: FormGroup;
    precioActual;
    hasData=false;

    @ViewChild('dialogContent', {static: false}) dialogContent: TemplateRef<any>;
    @ViewChild('precioForm', {static: false}) formTemplate: TemplateRef<any>;

    /**
     * Constructor
     */
    constructor(
        private _matDialog: MatDialog,
        private _fb: FormBuilder,
        private _apiService: ApiService,
        private _socketService: SocketioService,
        private _authService: AuthService
    )
    {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.precioActual = {adg: null,date: null, competencia:[{precio: null},{precio: null},{precio: null}]};
        this.form = this.createPrecioForm();
        if(this._authService.isAuthenticated()){
            this._apiService.getTodayPrecios().subscribe(data =>{
                if(data.length > 0 ){
                    this.precioActual= data[0];
                    this.form = this.createPrecioForm();
                    this.hasData = true;
                }           
            })
        }
    }
    ngOnInit(){
        /*this._socketService.socket.on('listo',(e) => {
            console.log('Listo desde server, ',e);
          }) */
    }
    edit(){
        this.dialogRef = this._matDialog.open(FormDialogComponent, {
            data: {
                action : 'edit',
                template: this.formTemplate,
                form: this.form,
                title: 'Actualiza el precio de Hoy',
                object: this.precioActual
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
               const precioHoy = this.form.value;
               this._apiService.updatePrecios(precioHoy,this.precioActual._id).subscribe(response => {
                   this.precioActual = response;
                   console.log(response);
               });
            }
            this.dialogRef = null;
        }); 
    }
    actualizaPrecio(){

    }
    nuevoPrecio(){
        this.dialogRef = this._matDialog.open(FormDialogComponent, {
            data: {
                action : 'create',
                template: this.formTemplate,
                form: this.form,
                title: 'Registra el precio de Hoy'
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
               const precioHoy = this.form.value;
               this._apiService.createPrecios(precioHoy).subscribe(response => {
                   console.log(response);
               });
            }
            this.dialogRef = null;
        });
    }

    createPrecioForm() {
        return this._fb.group({
            adg: [this.precioActual.adg],
            fecha: [this.precioActual.date || new Date()],
            competencia: new FormArray([
                new FormGroup({
                    marca: new FormControl('Multiregional'),
                    precio: new FormControl(this.precioActual.competencia[0].precio)
                }),
                new FormGroup({
                    marca: new FormControl('Portales'),
                    precio: new FormControl(this.precioActual.competencia[1].precio)
                }),
                new FormGroup({
                    marca: new FormControl('Vallejo'),
                    precio: new FormControl(this.precioActual.competencia[2].precio)
                })
            ])
        })
    }
}
