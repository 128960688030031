import { createAction, props } from "@ngrx/store";
import { Valvula } from 'adgas_shared';


/** Load Valvulas **/
export const LoadValvulas = createAction(
    '[Valvulas/API] Load Valvulas'
);

export const LoadValvulasSuccess = createAction(
    '[Valvulas/API] Load Valvulas Success',
    props<{valvulas: Valvula[]}>()
);

export const LoadValvulasFailed = createAction(
    '[Valvulas/API] Load Valvulas Failed',
    props<{error:any}>()
)
/** Create Valvulas **/
export const CreateValvula = createAction(
    '[Valvulas/API] Create Valvula',
    props<{valvula: Valvula}>()
);

export const CreateValvulaSuccess = createAction(
    '[Valvulas/API] Create Valvula Success',
    props<{valvula: Valvula}>()
);

export const CreateValvulaFailed = createAction(
    '[Valvulas/API] Create Valvula Failed',
    props<{error:any}>()
)
/** Update Valvulas **/
export const UpdateValvula = createAction(
    '[Valvulas/API] Update Valvula',
    props<{valvula: Valvula}>()
);

export const UpdateValvulaSuccess = createAction(
    '[Valvulas/API] Update Valvula Success',
    props<{valvula: Valvula}>()
);

export const UpdateValvulaFailed = createAction(
    '[Valvulas/API] Update Valvula Failed',
    props<{error:any}>()
)
/** Delete Valvulas **/
export const DeleteValvula = createAction(
    '[Valvulas/API] Delete Valvula',
    props<{_id: string}>()
);

export const DeleteValvulaSuccess = createAction(
    '[Valvulas/API] Delete Valvula Success',
    props<{_id: string}>()
);

export const DeleteValvulaFailed = createAction(
    '[Valvulas/API] Delete Valvula Failed',
    props<{error:any}>()
)