import { AuthService } from '../auth.service';

import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  NavigationExtras,
  CanLoad,
  CanActivateChild
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivateChild, CanActivate{

  constructor(
      public router: Router,
      public authService: AuthService
      ) {
      }

  canLoad(route: Route): boolean {
    if (!this.authService.isAuthenticated()) {
        this.router.navigate(['/login']);
        return false;
    }
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, url: any): boolean{
    if (!this.authService.isAuthenticated()) {
        this.router.navigate(['/login']);
        return false;
    }
      const rol = this.authService.getUser().rol.name; 
      if (route.data.role && route.data.role.includes(rol) === false) {
        this.router.navigate(['/home']);
        return false;
      }

    if (!this.authService.isAuthenticated()) {
        this.router.navigate(['/login']);
        return false;
    }
    return true;
  }
  canActivate(){
      console.log('Estamos Loggeados ? ');
    console.log(this.authService.isAuthenticated())
    if (!this.authService.isAuthenticated()) {
        this.router.navigate(['/login']);
        return false;
    }
    return true;
  }

  /* canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  } */

  /* checkLogin(url: string): boolean {
    if (this.singleton.isLoggedIn) {
      return true;
    }
    // Store the attempted URL for redirecting
    this.singleton.redirectUrl = url;

    // Create a dummy session id
    const sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      queryParams: { session_id: sessionId },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/'], navigationExtras);
    return false;
  } */
}
