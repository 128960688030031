import { createAction, props } from "@ngrx/store";
import { User } from 'adgas_shared';


/** Load Users **/
export const LoadUsers = createAction(
    '[Users/API] Load Users'
);

export const LoadUsersSuccess = createAction(
    '[Users/API] Load Users Success',
    props<{users: User[]}>()
);

export const LoadUsersFailed = createAction(
    '[Users/API] Load Users Failed',
    props<{error:any}>()
)
/** Create Users **/
export const CreateUser = createAction(
    '[Users/API] Create User',
    props<{user: User}>()
);

export const CreateUserSuccess = createAction(
    '[Users/API] Create User Success',
    props<{user: User}>()
);

export const CreateUserFailed = createAction(
    '[Users/API] Create User Failed',
    props<{error:any}>()
)
/** Update Users **/
export const UpdateUser = createAction(
    '[Users/API] Update User',
    props<{user: User}>()
);

export const UpdateUserSuccess = createAction(
    '[Users/API] Update User Success',
    props<{user: User}>()
);

export const UpdateUserFailed = createAction(
    '[Users/API] Update User Failed',
    props<{error:any}>()
)
/** Delete Users **/
export const DeleteUser = createAction(
    '[Users/API] Delete User',
    props<{_id: string}>()
);

export const DeleteUserSuccess = createAction(
    '[Users/API] Delete User Success',
    props<{_id: string}>()
);

export const DeleteUserFailed = createAction(
    '[Users/API] Delete User Failed',
    props<{error:any}>()
)