import { createAction, props } from "@ngrx/store";
import { Planta } from 'adgas_shared';


/** Load Plantas **/
export const LoadPlantas = createAction(
    '[Plantas/API] Load Plantas'
);

export const LoadPlantasSucces = createAction(
    '[Plantas/API] Load Plantas Success',
    props<{plantas: Planta[]}>()
);

export const LoadPlantasFailed = createAction(
    '[Plantas/API] Load Plantas Failed',
    props<{error:any}>()
)
/** Create Plantas **/
export const CreatePlanta = createAction(
    '[Plantas/API] Create Planta',
    props<{planta: Planta}>()
);

export const CreatePlantaSucces = createAction(
    '[Plantas/API] Create Planta Success',
    props<{planta: Planta}>()
);

export const CreatePlantaFailed = createAction(
    '[Plantas/API] Create Planta Failed',
    props<{error:any}>()
)
/** Update Plantas **/
export const UpdatePlanta = createAction(
    '[Plantas/API] Update Planta',
    props<{planta: Planta}>()
);

export const UpdatePlantaSucces = createAction(
    '[Plantas/API] Update Planta Success',
    props<{planta: Planta}>()
);

export const UpdatePlantaFailed = createAction(
    '[Plantas/API] Update Planta Failed',
    props<{error:any}>()
)
/** Delete Plantas **/
export const DeletePlanta = createAction(
    '[Plantas/API] Delete Planta',
    props<{_id: string}>()
);

export const DeletePlantaSucces = createAction(
    '[Plantas/API] Delete Planta Success',
    props<{_id: string}>()
);

export const DeletePlantaFailed = createAction(
    '[Plantas/API] Delete Planta Failed',
    props<{error:any}>()
)