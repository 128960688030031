import { Planta } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as plantasSelector from './plantas.selector';
import * as plantasActions from './plantas.actions';
import { Observable } from 'rxjs';
import { PlantasState } from './plantas.state';

@Injectable()
export class PlantasFacade {

  plantas$: Observable<Planta[]> = this.store.pipe(select(plantasSelector.getAllPlantas));

  constructor(private store: Store<PlantasState>) {}

  load(): void {
    this.store.dispatch(plantasActions.LoadPlantas());
  }

  update(planta: Planta): void {
    this.store.dispatch(plantasActions.UpdatePlanta({ planta }));
  }
  create(planta: Planta): void {
    this.store.dispatch(plantasActions.CreatePlanta({ planta }));
  }

  delete(_id: string): void {
    this.store.dispatch(plantasActions.DeletePlanta({ _id }));
  }

}
