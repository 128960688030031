import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as plantasActions from './plantas.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Planta } from 'adgas_shared';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PlantasEffects {
    constructor(
        private _actions$: Actions,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
    ) { }

    load$ = createEffect(() =>
    this._actions$.pipe(
        ofType(plantasActions.LoadPlantas),
        mergeMap( () => 
            this._apiService.getPlantas().pipe(
                map( (plantas:Planta[]) => plantasActions.LoadPlantasSucces({plantas})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(plantasActions.LoadPlantasFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
        ofType(plantasActions.CreatePlanta),
        mergeMap( ({planta}) => 
            this._apiService.createPlanta(planta).pipe(
                map( (planta:Planta) => plantasActions.CreatePlantaSucces({planta})),
                catchError((httpError: HttpErrorResponse) => {
                    this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                      duration: 3000,
                    });
                    return of(plantasActions.LoadPlantasFailed({ error: httpError.message }));
                })
            )
        )
    )
  );

  update$ = createEffect(() =>
  this._actions$.pipe(
      ofType(plantasActions.UpdatePlanta),
      mergeMap( ({planta}) => 
        this._apiService.updatePlanta(planta).pipe(
            map( planta => plantasActions.UpdatePlantaSucces({planta}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(plantasActions.LoadPlantasFailed({ error: httpError.message }));
            })
        )
      )
    )
  )

  delete$ = createEffect(() =>
  this._actions$.pipe(
      ofType(plantasActions.DeletePlanta),
      mergeMap( ({_id}) => 
        this._apiService.deletePlanta(_id).pipe(
            map( _id => plantasActions.DeletePlantaSucces({_id}) ),
            catchError((httpError: HttpErrorResponse) => {
                this._snackBar.open(`Can't load the API. Please verify your connection`, 'Ok', {
                  duration: 3000,
                });
                return of(plantasActions.LoadPlantasFailed({ error: httpError.message }));
            })
        )
      )
    )
  )
    
}