import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormDialogComponent implements OnInit {
  form;
  template;
  title = '';
  action;
  object = {updates:[], creation:{ creator: {nombre: '', apellidos: ''}, created: ''}};
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public matDialogRef: MatDialogRef<FormDialogComponent>
  ) { }

  ngOnInit(): void {
    this.title = this._data.title;
    this.template = this._data.template;
    this.form = this._data.form;
    this.action = this._data.action;
    this.object = this._data.object;
  }

}
