import { createAction, props } from "@ngrx/store";
import { Descarga } from 'adgas_shared';


/** Load Descargas **/
export const LoadDescargas = createAction(
    '[Descargas/API] Load Descargas',
    props<{pipaId: string}>()
);

export const LoadDescargasSuccess = createAction(
    '[Descargas/API] Load Descargas Success',
    props<{descargas: Descarga[]}>()
);

export const LoadDescargasFailed = createAction(
    '[Descargas/API] Load Descargas Failed',
    props<{error:any}>()
)
/** Create Descargas **/
export const CreateDescarga = createAction(
    '[Descargas/API] Create Descarga',
    props<{descarga: Descarga}>()
);

export const CreateDescargaSuccess = createAction(
    '[Descargas/API] Create Descarga Success',
    props<{descarga: Descarga}>()
);

export const CreateDescargaFailed = createAction(
    '[Descargas/API] Create Descarga Failed',
    props<{error:any}>()
)
/** Update Descargas **/
export const UpdateDescarga = createAction(
    '[Descargas/API] Update Descarga',
    props<{descarga: Descarga}>()
);

export const UpdateDescargaSuccess = createAction(
    '[Descargas/API] Update Descarga Success',
    props<{descarga: Descarga}>()
);

export const UpdateDescargaFailed = createAction(
    '[Descargas/API] Update Descarga Failed',
    props<{error:any}>()
)
/** Delete Descargas **/
export const DeleteDescarga = createAction(
    '[Descargas/API] Delete Descarga',
    props<{_id: string}>()
);

export const DeleteDescargaSuccess = createAction(
    '[Descargas/API] Delete Descarga Success',
    props<{_id: string}>()
);

export const DeleteDescargaFailed = createAction(
    '[Descargas/API] Delete Descarga Failed',
    props<{error:any}>()
)