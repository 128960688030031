import { createReducer, on, Action } from "@ngrx/store";
import * as tanquesActions from './tanques.actions';
import { Tanque } from 'adgas_shared';
import { initialState, adapter, TanquesState } from './tanques.state';
import { Update } from '@ngrx/entity';
const reducer = createReducer(
    initialState,
    on(
        tanquesActions.LoadTanques,
        tanquesActions.UpdateTanque,
        tanquesActions.CreateTanque,
        tanquesActions.DeleteTanque,
        state => ({...state, loading: true, loaded: false})
    ),
    on(
        tanquesActions.LoadTanquesSuccess,
        ( state,{tanques} ) => {
            return adapter.setAll(
                tanques,
                {...state, loading: false, loaded: true}
            );
        }
    ),
    on(
        tanquesActions.UpdateTanqueFailed,
        tanquesActions.LoadTanquesFailed,
        tanquesActions.CreateTanqueFailed,
        tanquesActions.DeleteTanqueFailed,
        ( state, {error} ) => ({...state, loading: false, loaded: false})
    ),
    on(
        tanquesActions.UpdateTanqueSuccess,
        (state, {tanque}) => {
            const tanqueUpdate: Update<Tanque> = {
                id: tanque._id,
                changes:{
                    ...tanque
                }
            };
            return adapter.updateOne(
                tanqueUpdate,
                {...state, loading: false, loaded: true}
            )
        }
    ),
    on(
        tanquesActions.CreateTanqueSuccess,
        (state, {tanque}) => {
            return adapter.addOne(
                tanque,
                {...state, loading:false, loaded: true}
            )
        }
    ),
    on(
        tanquesActions.DeleteTanqueSuccess,
        (state, {_id}) => {
            return adapter.removeOne(
                _id,
                {...state, loading: false, loaded: true}
            )
        }
    )
);

export function tanquesReducer(state: TanquesState | undefined, action: Action){
    return reducer(state, action);
}