import { createAction, props } from "@ngrx/store";
import { Operador } from 'adgas_shared';


/** Load Operadores **/
export const LoadOperadores = createAction(
    '[Operadores/API] Load Operadores'
);

export const LoadOperadoresSucces = createAction(
    '[Operadores/API] Load Operadores Success',
    props<{operadores: Operador[]}>()
);

export const LoadOperadoresFailed = createAction(
    '[Operadores/API] Load Operadores Failed',
    props<{error:any}>()
)
/** Create Operadores **/
export const CreateOperador = createAction(
    '[Operadores/API] Create Operadore',
    props<{operador: Operador}>()
);

export const CreateOperadorSucces = createAction(
    '[Operadores/API] Create Operadore Success',
    props<{operador: Operador}>()
);

export const CreateOperadorFailed = createAction(
    '[Operadores/API] Create Operadore Failed',
    props<{error:any}>()
)
/** Update Operadores **/
export const UpdateOperador = createAction(
    '[Operadores/API] Update Operador',
    props<{operador: Operador}>()
);

export const UpdateOperadorSucces = createAction(
    '[Operadores/API] Update Operador Success',
    props<{operador: Operador}>()
);

export const UpdateOperadorFailed = createAction(
    '[Operadores/API] Update Operador Failed',
    props<{error:any}>()
)
/** Delete Operadores **/
export const DeleteOperador = createAction(
    '[Operadores/API] Delete Operador',
    props<{_id: string}>()
);

export const DeleteOperadorSucces = createAction(
    '[Operadores/API] Delete Operador Success',
    props<{_id: string}>()
);

export const DeleteOperadorFailed = createAction(
    '[Operadores/API] Delete Operador Failed',
    props<{error:any}>()
)