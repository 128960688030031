import { Descarga } from 'adgas_shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DescargasState, adapter } from './descargas.state';

const getDescargasState =
  createFeatureSelector<DescargasState>('descargas');


export const { selectAll, selectEntities } = adapter.getSelectors(getDescargasState);

export const getDescargasByTypeSelector = (props:{type:string}) => {
    return createSelector(selectAll, (entities) => {
        return entities.filter( (descarga:Descarga) => descarga.tipo == props.type)
    } )
}

export const getDescargasById = (props:{_id:string}) => {
    return createSelector(selectAll, (entities) => {
        return entities.filter( (descarga:Descarga) => descarga._id == props._id)
    } )
}
