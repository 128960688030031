import { Valvula } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as valvulasSelector from './valvulas.selectors';
import * as valvulasActions from './valvulas.actions';
import { Observable } from 'rxjs';
import { ValvulasState } from './valvulas.state';

@Injectable()
export class ValvulasFacade {

  valvulas$: Observable<Valvula[]> = this.store.pipe(select(valvulasSelector.getAllValvulas));

  constructor(private store: Store<ValvulasState>) {}

  load(): void {
    this.store.dispatch(valvulasActions.LoadValvulas());
  }

  update(valvula: Valvula): void {
    this.store.dispatch(valvulasActions.UpdateValvula({ valvula }));
  }
  create(valvula: Valvula): void {
    this.store.dispatch(valvulasActions.CreateValvula({ valvula }));
  }

  delete(_id: string): void {
    this.store.dispatch(valvulasActions.DeleteValvula({ _id }));
  }

}
