import { User } from 'adgas_shared';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as usersSelector from './users.selectors';
import * as usersActions from './users.actions';
import { Observable } from 'rxjs';
import { UsersState } from './users.state';

@Injectable()
export class UsersFacade {

  users$: Observable<User[]> = this.store.pipe(select(usersSelector.selectAll));
  constructor(private store: Store<UsersState>) {}

  selectUsersByRol(rol:string[]):Observable<User[]>{
    return this.store.pipe(select(usersSelector.getUsersByRol(rol)));
  }
  load(): void {
    this.store.dispatch(usersActions.LoadUsers());
  }

  update(user: User): void {
    this.store.dispatch(usersActions.UpdateUser({ user }));
  }
  create(user: User): void {
    this.store.dispatch(usersActions.CreateUser({ user }));
  }

  delete(_id: string): void {
    this.store.dispatch(usersActions.DeleteUser({ _id }));
  }

}
