import { Planta } from 'adgas_shared';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector     : 'plantas-form-dialog',
    templateUrl  : './plantas-form.component.html',
    styleUrls    : ['./plantas-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PlantasFormDialogComponent
{
    action: string;
    planta: Planta;
    plantaForm: FormGroup;
    dialogTitle: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<PlantasFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<PlantasFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edita Planta';
            this.planta = _data.planta;
            console.log(this.planta);
        }
        else
        {
            this.dialogTitle = 'Agrega Planta';
            this.planta = {
                nombre: '',
                direccion:'',
                precio: 0,
                descuento: 0
            }
        }

        this.plantaForm = this.createPlantaForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createPlantaForm(): FormGroup
    {
        return this._formBuilder.group({
            nombre      : [this.planta.nombre],
            direccion    : [this.planta.direccion],
            descuento: [this.planta.descuento],
            precio  : [this.planta.precio]
        });
    }
}
