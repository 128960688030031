import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {MatTableModule} from '@angular/material/table';
import { FuseSharedModule } from '@fuse/shared.module';

import { PlantasComponent } from './plantas.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PlantasFormDialogComponent } from '../plantasForm/plantas-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';

const routes = [
    {
        path     : '',
        component: PlantasComponent
    }
];

@NgModule({
    declarations: [
        PlantasComponent,
        PlantasFormDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatInputModule,
        MatSortModule,
        FuseSharedModule
    ],
    exports: [
        PlantasComponent
    ]
})

export class PlantasModule
{
}
