import { FormGroup } from '@angular/forms';
import { PlantasFormDialogComponent } from './../plantasForm/plantas-form.component';
import { Planta } from 'adgas_shared';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { takeUntil } from 'rxjs/internal/operators';
import { PlantasFacade } from 'app/store/plantas/plantas.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'app/services/api.service';

@Component({
    selector     : 'plantas',
    templateUrl  : './plantas.component.html',
    styleUrls    : ['./plantas.component.scss'],
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PlantasComponent implements OnInit
{
    dataSource;
    dialogRef: any;
    plantas$:Observable<Planta[]> = this._plantasFacade.plantas$;
    plantas: Planta[] = [];
    displayedColumns = ['id', 'descuento', 'nombre', 'direccion', 'precio', 'active'];

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _plantasFacade: PlantasFacade,
        private _matDialog: MatDialog,
        private _localService: ApiService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._plantasFacade.load();
        this.plantas$.subscribe( (plantas:any) => {
            this.plantas = plantas;
            this.dataSource = new MatTableDataSource(this.plantas);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        })
        /* console.log('Empezamos con la data falas'); */
        /* this._localService.createFakeDataPlantas().subscribe( data => {
            console.log('Termino')
            console.log(data)
        }); */
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if ( !this.dataSource )
                {
                    return;
                }

                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }
     /**
     * New Planta
     */
    addPlanta(): void
    {
        this.dialogRef = this._matDialog.open(PlantasFormDialogComponent, {
            panelClass: 'plantas-form-dialog',
            data      : {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if ( !response )
                {
                    return;
                }
                this._plantasFacade.create(response.getRawValue());
                /* this._contactsService.updateContact(response.getRawValue()); */
            });
    }
    editPlanta(planta:Planta){
        this.dialogRef = this._matDialog.open(PlantasFormDialogComponent, {
            panelClass: 'plantas-form-dialog',
            data      : {
                planta: planta,
                action : 'edit'
            }
        });
        this.dialogRef.afterClosed()
        .subscribe(response => {
            if ( !response )
            {
                return;
            }
            const actionType: string = response[0];
            const formData: FormGroup = response[1];
            switch ( actionType )
            {
                /**
                 * Save
                 */
                case 'save':
                    let plantaToUpdate = { _id:planta._id, ...formData.getRawValue()};
                    this._plantasFacade.update(plantaToUpdate);
                    break;
                /**
                 * Delete
                 */
                case 'delete':

                    this._plantasFacade.delete(planta._id);

                    break;
            }
        });
    }
}


