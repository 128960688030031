import 'hammerjs';

import {
  CommonModule,
  registerLocaleData,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { environment } from 'environments/environment';

import { JwtModule } from '@auth0/angular-jwt';
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { appRoutes } from './app.routes';
import {
  FormDialogComponent,
} from './components/form-dialog/form-dialog.component';
import { MapModule } from './main/map/map.module';
import {
  ActivationUserComponent,
} from './main/pages/activation-user/activation-user.component';
import {
  Login2Module,
} from './main/pages/authentication/login-2/login-2.module';
import { PlantasModule } from './main/plantas/plantas.module';
import { AuthGuard } from './services/guards/auth-guard.service';
import { TokenInterceptor } from './services/guards/token.interceptor';
import { AlmacenesFacade } from './store/almacenes/almacenes.facade';
import { RootStoreModule } from './store/root-store.module';

registerLocaleData(localeEsMx, 'es');

@NgModule({
    declarations: [
        AppComponent,
        FormDialogComponent,
        ActivationUserComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        MatSnackBarModule,
        // Material
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        //Firebase
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        // App modules
        LayoutModule,
        PlantasModule,
        Login2Module,
        JwtModule,
        RootStoreModule,
        CommonModule,
        MatToolbarModule,
        MapModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'es' },
        AuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: resourceProviderFactory,
            deps: [AlmacenesFacade],
            multi: true
        },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule
{
}
export function resourceProviderFactory(provider: AlmacenesFacade) {
    return () => provider.load();
    }

